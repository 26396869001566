import StatusSelector from 'common/components/selectors/JobStatusSelector';
import JobTriggerPrototypeSelector from 'common/components/selectors/jobs/JobTriggerPrototypeSelector';
import JobPrioritySelector from 'common/components/selectors/jobs/JobPrioritySelector';
import MaintenanceJobTypeSelector from 'common/components/selectors/jobs/MaintenanceJobTypeSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import VesselSystemGroupSelector from 'common/components/selectors/VesselSystemGroupSelector';
import ClassNumbersSelector from '@/common/components/selectors/ClassNumbersSelector';

const columns = [
  {
    header: 'System',
    key: 'assignment_vessel_system_id',
    type: 'string',
    sort: true,
    hideFromDropdown: true,
    width: 3
  },
  {
    header: 'I',
    filterLabel: 'Importance',
    key: 'priority',
    type: 'collection',
    sort: true,
    hideFromDropdown: true,
    minWidth: 72,
    maxWidth: 72
  },
  {
    header: 'Title',
    key: 'title',
    canFilter: false,
    sort: true,
    width: 3
  },
  {
    header: 'Status',
    key: 'status_id',
    type: 'collection',
    sort: true,
    component: StatusSelector,
    componentRest: { isMulti: true },
    width: 1
  },
  {
    header: 'Type',
    key: 'job_type',
    sort: true,
    canFilter: false
  },
  {
    header: 'Periodicity',
    key: 'periodicity',
    sort: false,
    canFilter: false
  },
  {
    header: 'Last Done At',
    key: 'last_done_at',
    type: 'date',
    sort: false,
    canFilter: false,
    hideFromDropdown: true
  },
  {
    header: 'Due Date',
    key: 'due_date',
    type: 'date',
    sort: true
  },
  {
    header: 'Last Done R/H',
    key: 'last_done_rh',
    type: 'number',
    sort: false,
    canFilter: false,
    hideFromDropdown: true,
    className: 'text-end justify-content-end'
  },
  {
    header: 'R/H Due on',
    key: 'due_on_rh',
    type: 'number',
    sort: false,
    canFilter: false,
    hideFromDropdown: true,
    className: 'text-end justify-content-end rounded-end rounded-lg'
  },
  {
    header: 'Job Prototype',
    key: 'prototype_id',
    type: 'collection',
    sort: true,
    hidden: true,
    hiddenOnBoard: true,
    component: JobTriggerPrototypeSelector,
    componentRest: { isMulti: true, showDefaultOptions: true, listParams: { is_maintenance: true } }
  },
  {
    header: 'Job Title',
    key: 'job_title',
    type: 'string',
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'System',
    key: 'system_title',
    type: 'string',
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Job Importance',
    key: 'importance_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: JobPrioritySelector,
    componentRest: {
      isMulti: true,
      showDefaultOptions: true
    },
    hidden: true
  },
  {
    header: 'Job Type',
    key: 'maintenance_job_type_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: MaintenanceJobTypeSelector,
    componentRest: {
      isMulti: true,
      showDefaultOptions: true
    },
    hidden: true
  },
  {
    header: 'Due changed',
    key: 'due_date_changed',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Periodicity As Needed',
    key: 'periodicity_as_needed',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Safety',
    key: 'is_safety',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Due Date/RH Changed',
    key: 'due_date_due_on_rh_changed',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Drydock',
    key: 'is_drydock',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Job Office Department',
    key: 'department_id',
    hidden: true,
    canFilter: true,
    type: 'collection',
    component: DepartmentSelector
  },
  {
    header: 'Job Vessel Department',
    key: 'main_department_id',
    hidden: true,
    canFilter: true,
    type: 'collection',
    component: DepartmentSelector,
    componentRest: {
      onlyCrewDepartments: true
    }
  },
  {
    header: 'Has Comments/Attachments',
    key: 'has_comments_or_attachments',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Has linked Forms',
    key: 'has_forms',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Periodicity Interval',
    key: 'periodicity_interval',
    type: 'periodicity_interval',
    canFilter: true,
    hidden: true
  },
  {
    header: 'Periodicity Running Hours',
    key: 'periodicity_running_hours',
    type: 'number',
    canFilter: true,
    hidden: true
  },
  {
    header: 'System group',
    key: 'vessel_system_group_id',
    hidden: true,
    canFilter: true,
    type: 'collection',
    component: VesselSystemGroupSelector,
    componentRest: {
      isMulti: true
    }
  },
  {
    header: 'Class Number',
    key: 'class_no',
    type: 'collection',
    component: ClassNumbersSelector,
    componentRest: {
      isMulti: true
    },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Has Attached Manual',
    key: 'has_attached_manual',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  }
];

export default columns;
