import moment from 'moment';
import JobTriggerPrototypeSelector from '@/common/components/selectors/jobs/JobTriggerPrototypeSelector';
import MaintenanceJobTypeSelector from '@/common/components/selectors/jobs/MaintenanceJobTypeSelector';
import JobStatusSelector from '@/common/components/selectors/JobStatusSelector';
import JobPrioritySelector from '@/common/components/selectors/jobs/JobPrioritySelector';
import DepartmentSelector from '@/common/components/selectors/departments/DepartmentSelector';
import VesselSystemGroupSelector from '@/common/components/selectors/VesselSystemGroupSelector';
import SparePartsSelector from '@/common/components/selectors/SparePartsSelector';
import VesselSystemInfo from '../../components/VesselSystemInfo';
import Priority from '@/common/components/general/Priority';
import JobLink from '@/common/components/jobs/maintenance/components/JobLink';
import JobStatus from '@/common/components/pms/jobs/components/JobStatus';
import DueDate from '@/common/components/jobs/_base/components/DueDate';
import DueOnRh from '@/common/components/pms/jobs/components/DueOnRh';
import Resubmitted from '@/common/components/forms/_components/Resubmitted';
import paths from '@/routing/routes/_paths';
import { periodicityEnums } from '@/common/utils/fixed';
import DownloadAttachment from '@/common/components/buttons/DownloadAttachment';
import { downloadAllFilesOfEntity } from '@/api/files/api';
import NamesInTooltip from '@/common/components/general/NamesInTooltip';
import TableFilterBooleanSelector from '@/common/components/selectors/TableFilterBooleanSelector';
import TableFilterDateSelector from '@/common/components/selectors/TableFilterDateSelector';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import { updateJob } from 'common/components/jobs/_base/store/actions';
import { jobEnums } from '@/common/utils/fixed';
import ClassNumbersSelector from '@/common/components/selectors/ClassNumbersSelector';

const updateStatus = async (id, params, previousStatus, dispatch) => {
  // Do not refetch table data when updating a job's status
  await dispatch(
    updateJob({ id: id, ...params }, false, false, {
      field: 'status_id',
      value: previousStatus.id
    })
  );
};

export const getColumns = (
  { jobStatuses, doneJobsFilterEnabled, isOnBoard },
  { history, dispatch, setActive, setIsModalOpen }
) => {
  const columns = [
    {
      header: 'System',
      key: 'assignment_vessel_system_id',
      size: 368,
      type: 'string',
      canSort: true,
      canFilter: false,
      isHandlingCompactView: true,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().expanded.isCompactViewEnabled;

        return (
          <VesselSystemInfo
            allowOverflow={!isCompactViewEnabled}
            key={`vessel-system-${original.id}`}
            vesselSystem={{
              description: original.assignment_description,
              extra_description: original.assignment_extra_description,
              parent_id: original.assignment_parent_id
            }}
            attributes={{
              is_class: original?.assignment_is_class,
              is_critical: original?.is_critical,
              is_environmental_critical: original?.is_environmental_critical,
              is_navigational: original?.is_navigational
            }}
            cmsReferenceCode={original?.assignment_cms_reference_code}
            showGroup={false}
          />
        );
      },
      isSticky: true
    },
    {
      header: 'I',
      key: 'importance',
      canSort: true,
      canFilter: false,
      isSticky: true,
      size: 72,
      row: ({ row: { original } }) => <Priority value={original.importance_id} />
    },
    {
      header: 'Title',
      key: 'title',
      canFilter: false,
      canSort: true,
      // headerClassName: 'title-column-header',
      isSticky: true,
      size: 368,
      isHandlingCompactView: true,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().expanded.isCompactViewEnabled;

        return (
          <JobLink
            isPms={true}
            job={original}
            allowOverflow={!isCompactViewEnabled}
            vesselName={original?.vessel_name}
            onClick={e => {
              e.preventDefault();

              history.replace({
                pathname: `${paths.pms_jobs}/${original?.id}`,
                search: history.location.search
              });
            }}
          />
        );
      }
    },
    {
      header: 'Vessel',
      key: 'vessel_name',
      canFilter: false,
      className: 'ps-2',
      canSort: true,
      size: 172,
      row: ({ row: { original } }) => original?.vessel_name || '-',
      hidden: isOnBoard
    },
    {
      header: 'Status',
      key: 'status_id',
      canSort: true,
      canFilter: true,
      type: 'collection',
      component: JobStatusSelector,
      componentRest: {
        isMulti: true,
        showDefaultOptions: true
      },
      size: 192,
      className: 'overflow-hidden',
      row: ({ row: { original } }) => {
        const status = jobStatuses.find(st => st.id === original.status_id);
        return (
          <div className="d-flex align-items-center">
            <JobStatus
              className="w-auto"
              status={status}
              onJobUpdate={params => updateStatus(original.id, params, status, dispatch)}
            />

            <Resubmitted className={`cms-4`} isResubmitted={original?.resubmitted} />
          </div>
        );
      }
    },
    {
      header: 'Type',
      key: 'job_type',
      canSort: true,
      canFilter: false,
      size: 143,
      row: ({ row: { original } }) => original?.maintenance_job_type_name || '-'
    },
    {
      header: 'Periodicity',
      key: 'periodicity',
      canSort: true,
      canFilter: false,
      size: 144,
      row: ({ row: { original } }) => original?.periodicity_description
    },

    {
      header: 'Periodicity Interval',
      key: 'periodicity_interval',
      type: 'periodicity_interval',
      canFilter: true,
      canSort: false,
      hidden: true
    },

    {
      header: 'Periodicity Running Hours',
      key: 'periodicity_running_hours',
      type: 'number',
      canFilter: true,
      canSort: false,
      hidden: true
    },
    {
      header: 'Last Done At',
      key: 'last_done_at',
      type: 'date',
      canFilter: false,
      canSort: false,
      size: 143,
      component: TableFilterDateSelector,
      row: ({ row: { original } }) => moment(original.last_done_at).format('DD/MM/YYYY') || '-'
    },
    {
      header: 'Done At',
      key: 'carried_out_at',
      type: 'date',
      canSort: true,
      canFilter: true,
      size: 143,
      component: TableFilterDateSelector,
      row: ({ row: { original } }) => moment(original?.carried_out_at).format('DD/MM/YYYY') || '-'
    },
    {
      header: 'Due Date',
      key: 'due_date',
      type: 'date',
      canSort: true,
      canFilter: false,
      size: 115,
      component: TableFilterDateSelector,
      isHandlingCompactView: true,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().expanded.isCompactViewEnabled;

        return original?.periodicity?.type === periodicityEnums.as_needed ? null : (
          <DueDate
            Wrapper={isCompactViewEnabled ? TextWithTooltip : 'div'}
            allowOverflow={!isCompactViewEnabled}
            className="fs-12"
            dueDate={original.due_date}
            dueDateChanged={original.due_date_changed_at || original.due_on_rh_changed_at}
            showTime={original.due_date_show_time}
            createdAt={original.assignment_created_at}
            status={original.status_id}
            condition={{
              is_becoming_due_date: original.is_becoming_due_date,
              is_overdue_date: original.is_overdue_date
            }}
          />
        );
      }
    },
    {
      header: 'Last Done R/H',
      key: 'last_done_rh',
      type: 'number',
      canSort: false,
      canFilter: false,
      className: 'text-end justify-content-end',
      size: 143,
      row: ({ row: { original } }) => {
        if (doneJobsFilterEnabled) {
          return original?.total_system_running_hours || '-';
        } else {
          return original?.last_done_rh || '-';
        }
      }
    },
    {
      header: 'R/H Due on',
      key: 'due_on_rh',
      type: 'number',
      canSort: true,
      canFilter: false,
      className: 'text-end justify-content-end rounded-end rounded-lg',
      size: 130,
      row: ({ row: { original } }) => (
        <DueOnRh
          className="fs-12 d-flex align-items-center justify-content-end"
          dueOnRh={original?.due_on_rh}
          condition={{
            is_becoming_due_rh: original.is_becoming_due_rh,
            is_overdue_rh: original.is_overdue_rh
          }}
        />
      )
    },
    {
      header: 'LAST R/H REPORT',
      key: 'last_rh_report',
      type: 'string',
      canSort: false,
      canFilter: false,
      className: 'rounded-start rounded-lg',
      size: 180,
      row: ({ row: { original } }) =>
        original?.last_date_of_running_hours_reading ? (
          <>
            {moment(original?.last_date_of_running_hours_reading).format('DD/MM/YYYY')}
            <span className="cms-6 cme-6">|</span>
            {original?.running_hours} rh
          </>
        ) : (
          <div>-</div>
        )
    },
    {
      header: 'R/H Left',
      key: 'rh_left',
      type: 'number',
      canSort: false,
      canFilter: false,
      className: 'text-end justify-content-end',
      size: 80,
      row: ({ row: { original } }) => original?.rh_left || '-'
    },
    {
      header: '',
      visibilityLabel: 'Library Attachments',
      key: 'maintenance_attachments',
      canSort: false,
      canFilter: false,
      size: 60,
      row: ({ row: { original } }) => (
        <div className="d-flex justify-content-start overflow-y-hidden">
          <DownloadAttachment
            downloadFiles={() =>
              downloadAllFilesOfEntity({ id: original.id, type: 'job_maintenance_details' })
            }
            popupPlacement="left"
            data={{
              attachments: original.maintenance_attachments,
              attachments_count: original.maintenance_attachments?.length,
              id: original?.id
            }}
            hasAttachments={original.maintenance_attachments?.length > 0}
          />
        </div>
      )
    },
    {
      header: 'Forms to be completed',
      key: 'forms_completed',
      type: 'string',
      canSort: false,
      canFilter: false,
      size: 200,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().expanded.isCompactViewEnabled;

        return (
          <NamesInTooltip
            maxHeight={isCompactViewEnabled ? 18 : 'auto'}
            className="h-100p"
            showThreeDots={isCompactViewEnabled}
            names={original?.forms?.map(f => f?.form?.name)}
          />
        );
      }
    },
    {
      header: '',
      visibilityLabel: 'Job Attachments',
      key: 'attachments',
      canSort: false,
      canFilter: false,
      size: 60,
      row: ({ row: { original } }) => (
        <div className="d-flex justify-content-start">
          <DownloadAttachment
            downloadFiles={() => downloadAllFilesOfEntity({ id: original.id, type: 'job' })}
            popupPlacement="left"
            data={{
              attachments: original.attachments,
              attachments_count: original.attachments?.length,
              id: original?.id
            }}
            hasAttachments={original.attachments?.length > 0}
          />
        </div>
      )
    },
    {
      header: 'Job Prototype',
      key: 'prototype_id',
      type: 'collection',
      canSort: true,
      canFilter: !isOnBoard,
      hidden: true,
      component: JobTriggerPrototypeSelector,
      componentRest: {
        isMulti: true,
        showDefaultOptions: true,
        listParams: { is_maintenance: true }
      }
    },
    {
      header: 'System',
      key: 'system_title',
      type: 'string',
      canSort: false,
      canFilter: true,
      hidden: true
    },
    {
      header: 'Done before due',
      key: 'done_before_due',
      type: 'percentage',
      canSort: false,
      canFilter: true,
      hidden: true
    },
    {
      header: 'Job Title',
      key: 'job_title',
      type: 'string',
      canSort: false,
      canFilter: true,
      hidden: true
    },
    {
      header: 'Job Type',
      key: 'maintenance_job_type_id',
      type: 'collection',
      canSort: false,
      canFilter: true,
      component: MaintenanceJobTypeSelector,
      componentRest: {
        isMulti: true,
        showDefaultOptions: true
      },
      hidden: true
    },
    {
      header: 'Job Importance',
      key: 'importance_id',
      type: 'collection',
      canSort: false,
      canFilter: true,
      component: JobPrioritySelector,
      componentRest: {
        isMulti: true,
        showDefaultOptions: true
      },
      hidden: true
    },
    {
      header: 'Critical',
      key: 'is_critical',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Periodicity As Needed',
      key: 'periodicity_as_needed',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Has Comments/Attachments',
      key: 'has_comments_or_attachments',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Class',
      key: 'is_class',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Environmental',
      key: 'is_environmental_critical',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Navigational',
      key: 'is_navigational',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Has linked Forms',
      key: 'has_forms',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Due changed',
      key: 'due_date_changed',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Due Date/RH Changed',
      key: 'due_date_due_on_rh_changed',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Safety',
      key: 'is_safety',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Drydock',
      key: 'is_drydock',
      type: 'boolean',
      canSort: false,
      canFilter: true,
      hidden: true,
      component: TableFilterBooleanSelector
    },
    {
      header: 'Job Office Department',
      key: 'department_id',
      hidden: true,
      canFilter: true,
      canSort: false,
      type: 'collection',
      component: DepartmentSelector
    },
    {
      header: 'Job Vessel Department',
      key: 'main_department_id',
      hidden: true,
      canFilter: true,
      canSort: false,
      type: 'collection',
      component: DepartmentSelector,
      componentRest: {
        onlyCrewDepartments: true
      }
    },
    {
      header: 'System group',
      key: 'vessel_system_group_id',
      hidden: true,
      canSort: false,
      canFilter: true,
      type: 'collection',
      component: VesselSystemGroupSelector,
      componentRest: {
        isMulti: true
      }
    },
    {
      header: 'Consumed Spare Parts',
      key: 'consumed_spare_parts',
      type: 'collection',
      canSort: true,
      hidden: true,
      canFilter: !isOnBoard,
      component: SparePartsSelector,
      componentRest: { isMulti: true }
    },
    {
      header: 'Class Number',
      key: 'class_no',
      type: 'collection',
      component: ClassNumbersSelector,
      componentRest: { isMulti: true },
      canFilter: true,
      canSort: false,
      hidden: true
    },
    {
      key: 'actions',
      actions: ({ original }) =>
        original.job_type === jobEnums.unscheduled
          ? [
              {
                type: 'delete',
                onClick: () => {
                  setActive(original);
                  setIsModalOpen(true);
                }
              }
            ]
          : []
    }
  ];

  return columns;
};
