import React, { useState } from 'react';
import { func } from 'prop-types';

import { moduleIcons } from '../config';
import {
  selectActiveModulesLabels,
  selectEventModules,
  selectIsCreate,
  selectIsTemplate,
  selectisEditingModules
} from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeModule,
  reorderModules,
  setActiveModulesLabels,
  updateModule
} from 'events/store/events/actions';
import SvgRender from 'common/components/general/SvgRender';
import CircledButton from 'common/components/buttons/CircledButton';

const ModuleSlider = ({ toggleModal, setDeleting, setLabelToDelete, left }) => {
  const [dragged, setDragged] = useState();

  const dispatch = useDispatch();

  const modules = useSelector(selectEventModules);
  const activeModulesLabels = useSelector(selectActiveModulesLabels);
  const isEditingModules = useSelector(selectisEditingModules);
  const isCreate = useSelector(selectIsCreate);
  const isTemplate = useSelector(selectIsTemplate);

  const onModulePress = label => {
    if (!isEditingModules) {
      dispatch(setActiveModulesLabels(label));

      return;
    }

    if (isEditingModules && isCreate) {
      const module = modules.find(el => el.type.label === label);

      if (!module) {
        return;
      }

      setDeleting(true);
      dispatch(
        removeModule({
          id: module.id,
          isCreate,
          labelToDelete: label
        })
      );
    } else {
      toggleModal();
      setLabelToDelete(label);
    }
  };

  const handleModuleReorder = (oldIndex, newIndex, moduleId) => {
    dispatch(reorderModules(oldIndex, newIndex));

    if (isTemplate || isCreate) return;

    dispatch(updateModule({ id: moduleId, sort_index: newIndex }));
  };

  if (!modules?.length)
    return (
      <div className="event-toolbar__area-empty d-flex align-items-center justify-content-center text-primary">
        +
      </div>
    );

  return (
    <div
      style={{ left: `${left}%` }}
      className="event-toolbar__slider-container-slides d-flex align-items-center cpb-4"
    >
      {modules
        .filter(el => moduleIcons[el.type.label])
        .map((module, index) => {
          const moduleLabel = module.type.label;
          const moduleName = module.type.name;
          const isActive = activeModulesLabels?.includes(moduleLabel);
          return (
            <div
              onClick={() => onModulePress(moduleLabel)}
              key={module.id.toString()}
              className="event-toolbar__single-module d-flex flex-column align-items-center cursor-pointer position-relative"
              data-cy={moduleName}
              data-id={module.id}
              draggable="true"
              onDragStart={e => {
                setDragged(e.currentTarget.getAttribute('data-id'));
                e.dataTransfer.setData('text/plain', index.toString());
              }}
              onDragOver={e => {
                e.preventDefault();
              }}
              onDrop={e => {
                const fromIndex = e.dataTransfer.getData('text/plain');
                const toIndex = index;
                if (dragged) {
                  handleModuleReorder(parseInt(fromIndex), parseInt(toIndex), dragged);
                }
                setDragged(null);
              }}
            >
              <div className="fs-9 text-primary fw-medium event-module-name">{moduleName}</div>
              <SvgRender
                src={
                  isEditingModules
                    ? moduleIcons[moduleLabel].icon
                    : moduleIcons[moduleLabel].iconWithLetter
                }
                style={{ width: 56, height: 56 }}
              />

              {isActive && !isEditingModules && <div className="event-toolbar__selected-dot" />}

              {isEditingModules ? (
                <CircledButton
                  className="event-toolbar-delete"
                  svgStyle={{ width: 7 }}
                  type={'remove'}
                />
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

export default ModuleSlider;

ModuleSlider.propTypes = {
  toggleModal: func.isRequired,
  setDeleting: func.isRequired,
  setLabelToDelete: func.isRequired
};
