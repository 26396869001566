import { useCallback } from 'react';
import useRouter from 'use-react-router';

const useTableNavigation = () => {
  const { history } = useRouter();

  const navigate = useCallback(
    (search: string) => {
      history.push({ pathname: history.location.pathname, search });
    },
    [history]
  );

  return { navigate };
};

export default useTableNavigation;
