import { dateToLocal, dateToUtc, getCustomFormat } from '@/utils/dates';
import moment from 'moment';
import DateInput, { mode } from '../form/inputs/date';
import NumberInput from '@/ts-common/components/form/inputs/NumberInput';
import { strToNumber } from '@/ts-common/utils/numbers';

function TableFilterDateSelector({ filter, updateFilter }) {
  const defaultFilterValuesYearStart =
    filter.column?.defaultFilterValues?.[filter?.operation?.value]?.yearStart;

  const defaultFilterValuesYearEnd =
    filter.column?.defaultFilterValues?.[filter?.operation?.value]?.yearEnd;

  if (filter.operation && filter.operation.value === 'between') {
    const dates =
      !filter.value || filter.value === ''
        ? { from: null, to: null }
        : filter.column.showUtc
        ? {
            from: moment(filter.value.from),
            to: moment(filter.value.to)
          }
        : {
            from: dateToLocal(filter.value.from),
            to: dateToLocal(filter.value.to)
          };
    return (
      <DateInput
        className="filter-date-input mb-0"
        activeMode={mode.range.date}
        onChange={({ from, to }) => {
          if (filter.column.type === 'date') {
            updateFilter({ value: { from, to } });
          } else if (filter.column.showUtc) {
            updateFilter({
              value: {
                from: getCustomFormat(from, 'YYYY-MM-DD HH:mm'),
                to: getCustomFormat(to, 'YYYY-MM-DD HH:mm')
              }
            });
          } else {
            updateFilter({
              value: {
                from: dateToUtc(from, 'startOf', 'YYYY-MM-DD HH:mm'),
                to: dateToUtc(to, 'endOf', 'YYYY-MM-DD HH:mm')
              }
            });
          }
        }}
        placeholder="Starts - Ends"
        useDateInput={false}
        useStringValue={true}
        yearStart={defaultFilterValuesYearStart}
        yearEnd={defaultFilterValuesYearEnd}
        {...dates}
      />
    );
  } else if (
    filter.operation &&
    (filter.operation.value === 'lastDays' || filter.operation.value === 'nextDays')
  ) {
    return (
      <NumberInput
        placeholder="No. of days"
        onChange={event => {
          const { value } = event.target;

          updateFilter({ value: value ? [strToNumber(value), moment.tz.guess()] : '' });
        }}
        value={filter.value?.[0] || ''}
        isAllowed={val => val.value === '' || val.floatValue > 0}
      />
    );
  } else if (
    filter.operation &&
    (filter.operation.value === 'beforeDaysFromToday' ||
      filter.operation.value === 'afterDaysFromToday')
  ) {
    return (
      <NumberInput
        placeholder="No. of days"
        onChange={event => updateFilter(strToNumber(event.target.value))}
        value={filter.value || ''}
        isAllowed={val => (val.value.includes('.') ? false : true)}
      />
    );
  } else {
    return (
      <DateInput
        className="filter-date-input mb-0"
        value={
          filter.column.showUtc && filter.value ? moment(filter.value) : dateToLocal(filter.value)
        }
        onChange={date => {
          if (filter.column.type === 'date') {
            return updateFilter({ value: date });
          } else if (filter.column.showUtc) {
            return updateFilter({
              value: getCustomFormat(date, 'YYYY-MM-DD HH:mm')
            });
          } else {
            return updateFilter({ value: dateToUtc(date, 'startOf', 'YYYY-MM-DD HH:mm') });
          }
        }}
        placeholder="Select day"
        useDateInput={false}
        useStringValue={true}
        yearStart={defaultFilterValuesYearStart}
        yearEnd={defaultFilterValuesYearEnd}
      />
    );
  }
}

export default TableFilterDateSelector;
