import { selectCrewProfile } from 'crew/store/profile/selectors';
import { Row, Col } from 'reactstrap';

import _get from 'lodash/get';
import { durationInYears } from '@/crew/utils/helpers';
import { useAppSelector } from '@/store/hooks';
import { isAuthorized } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';
import { selectAccount } from '@/store/account/selectors';

const Stats = () => {
  const crew = useAppSelector(selectCrewProfile);
  const account = useAppSelector(selectAccount);
  const isVisible = isAuthorized(account, [permissions.office.crew.seaman_profile.contracts.view]);

  if (!isVisible) return null;

  const totals = [
    {
      key: 'total_company_service',
      description: 'Company Service'
    },
    {
      key: 'total_rank_service',
      description: 'Service in Rank'
    },
    {
      key: 'total_sea_service',
      description: 'Total Sea Service'
    }
  ];

  return (
    <Row className="crew-stats ">
      {totals.map((t, index) => (
        <Col key={index}>
          <div className={`d-flex flex-column w-100p crew-stats__box crew-stats__box--${t.key}`}>
            <div className="text-violet pb-1 mb-1 border-bottom lh-15 fs-12 w-100p">
              {t.description}
            </div>
            <div className="fw-medium d-flex align-items-end text-primary">
              <strong className="fs-18 lh-1 fw-medium">
                {durationInYears(_get(crew, `${t.key}.time`, 0))}
              </strong>
              <span className="cps-2 fs-12 lh-1 fw-medium">years</span>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default Stats;
